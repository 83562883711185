export default class LdrsBaseElement extends HTMLElement {
    _propsToUpgrade = {};
    shadow;
    template;
    defaultProps;
    isAttached = false;
    constructor() {
        super();
        this.shadow = this.attachShadow({ mode: 'open' });
        this.template = document.createElement('template');
    }
    storePropsToUpgrade(props) {
        props.forEach((prop) => {
            if (this.hasOwnProperty(prop) && typeof this[prop] !== 'undefined') {
                this._propsToUpgrade[prop] = this[prop];
                delete this[prop];
            }
        });
    }
    upgradeStoredProps() {
        Object.entries(this._propsToUpgrade).forEach(([key, val]) => {
            this.setAttribute(key, val);
        });
    }
    reflect(props) {
        props.forEach((prop) => {
            Object.defineProperty(this, prop, {
                set(val) {
                    if ('string,number'.includes(typeof val)) {
                        this.setAttribute(prop, val.toString());
                    }
                    else {
                        this.removeAttribute(prop);
                    }
                },
                get() {
                    return this.getAttribute(prop);
                },
            });
        });
    }
    applyDefaultProps(props) {
        this.defaultProps = props;
        Object.entries(props).forEach(([key, defaultValue]) => {
            this[key] = this[key] || defaultValue.toString();
        });
    }
}
